import React from "react";
import "./LoaderLogo.css";

class LogoLoader extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 961.5 961.5"
        version="1.1"
        viewBox="0 0 961.5 961.5"
        xmlSpace="preserve"
        className="raw_logo"
        width="50%"
        height="40%"
      >
        <path
          fill={theme.body}
          stroke={theme.body}
          stroke-width="4"
          d="M315.6 850.35c0-33.1-26.899-60-60-60H150c-16.5 0-30-13.5-30-30v-559.2c0-16.5 13.5-30 30-30h107.5c33.1 0 60-26.9 60-60s-26.9-60-60-60H150c-82.7 0-150 67.3-150 150v559.2c0 82.7 67.3 150 150 150h105.5c33.2 0 60.1-26.9 60.1-60zM706 910.35h105.5c82.7 0 150-67.3 150-150v-559.2c0-82.7-67.3-150-150-150H704c-33.101 0-60 26.9-60 60s26.899 60 60 60h107.5c16.5 0 30 13.5 30 30v559.2c0 16.5-13.5 30-30 30H706c-33.101 0-60 26.9-60 60s26.899 60 60 60z"
        ></path>
        <path
          fill={theme.body}
          stroke={theme.body}
          stroke-width="4"
          d="M674.899 686.15c17.5-27.5 26.301-57.1 26.301-88.7 0-34.8-7.301-64.1-22-87.699-14.7-23.7-34.9-42.301-60.9-56-25.9-13.601-65.9-26.9-120-39.601-54.1-12.8-88.2-25-102.2-36.8-11-9.3-16.5-20.4-16.5-33.4 0-14.3 5.9-25.7 17.7-34.2 18.3-13.3 43.6-19.9 75.9-19.9 31.3 0 54.8 6.2 70.399 18.6 9.601 7.6 17.101 18.2 22.601 31.8 7 17.4 24.1 28.6 42.8 27.8l26.5-1.2c29.7-1.3 49.5-31.1 39.399-59.1-9.1-25-23.899-46.5-44.6-64.5-34.9-30.3-87-45.5-156.1-45.5-42.301 0-78.5 6.4-108.4 19.2-29.9 12.8-52.8 31.4-68.8 55.8-15.9 24.4-23.9 50.7-23.9 78.7 0 43.6 16.9 80.5 50.7 110.8 24 21.5 65.9 39.699 125.5 54.5 46.3 11.5 76 19.5 89 24 19 6.8 32.4 14.699 40 23.899 7.6 9.101 11.5 20.2 11.5 33.2 0 20.3-9.1 38-27.2 53.2-18.2 15.2-45.1 22.7-81 22.7-33.8 0-60.7-8.5-80.6-25.5-13.4-11.5-23.8-27.4-31.2-47.7-6.8-18.9-25.6-30.7-45.6-28.8l-22.5 2.199c-27.9 2.7-46 30.4-37.7 57.101 11.4 36.5 30.1 66 56 88.3 37.3 32.2 90.8 48.3 160.399 48.3 47.801 0 87.801-6.7 119.801-20.1 32.401-13.399 57.2-33.899 74.7-61.399z"
        ></path>
      </svg>
    );
  }
}

export default LogoLoader;
